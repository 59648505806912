import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

export interface SignupState {
  email: string;
  password: string;
  userDetails: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
  };
  holdingDetails: {
    holdingName: string;
    cphNumber: string;
    flockNumber: string;
    lambingPeriod?: string;
    address?: {
      raw: string;
      street_number: string;
      route: string;
      town: string;
      county?: string;
      country: string;
      postcode: string;
      placeId?: string;
      latitude?: number;
      longitude?: number;
    };
  };
  currentStep: number;
  inviteAccepted: boolean;
  holdingName?: string;
}

@Injectable({
  providedIn: "root",
})
export class SignupStateService {
  private initialState: SignupState = {
    email: "",
    password: "",
    userDetails: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
    },
    holdingDetails: {
      holdingName: "",
      cphNumber: "",
      flockNumber: "",
    },
    currentStep: 1,
    inviteAccepted: false,
  };

  private signupState = new BehaviorSubject<SignupState>(this.initialState);

  getCurrentState() {
    return this.signupState.asObservable();
  }

  updateState(newState: Partial<SignupState>) {
    const currentState = this.signupState.value;
    this.signupState.next({
      ...currentState,
      ...newState,
      userDetails: {
        ...currentState.userDetails,
        ...(newState.userDetails || {}),
      },
      holdingDetails: {
        ...currentState.holdingDetails,
        ...(newState.holdingDetails || {}),
      },
      inviteAccepted: newState.inviteAccepted || false,
    });
  }

  resetState() {
    this.signupState.next(this.initialState);
  }
}
