import { Injectable } from "@angular/core";
import { Posthog } from "@capawesome/capacitor-posthog";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class PosthogService {
  constructor() {
    this.initPosthog();
  }

  private async initPosthog() {
    await Posthog.setup({
      apiKey: environment.posthog.apiKey,
      host: environment.posthog.host,
    });
  }

  async capture(eventName: string, properties?: any) {
    await Posthog.capture({
      event: eventName,
      properties: properties,
    });
  }

  async group(type: string, key: string, properties?: any) {
    await Posthog.group({
      type: type,
      key: key,
      groupProperties: properties,
    });
  }
}
