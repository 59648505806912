import { Component, ViewChild, NgZone, Input, Output, EventEmitter, AfterViewInit } from "@angular/core";
import { GooglePlacesService } from "src/app/shared/services/google-places.service";
import { IonInput } from "@ionic/angular";
import { Observable } from "rxjs";
import { CoreService } from "src/app/shared/core/core.service";

export type PlaceResult = {
  address: string;
  placeId: string;
  details?: google.maps.places.PlaceResult;
};

@Component({
  selector: "app-address-autocomplete",
  templateUrl: "./address-autocomplete.component.html",
  styleUrls: ["./address-autocomplete.component.scss"],
})
export class AddressAutocompleteComponent implements AfterViewInit {
  @ViewChild("searchInput") searchInput!: IonInput;
  @Output() placeChanged = new EventEmitter<PlaceResult>();

  @Input() placeholder = "Search for an address";
  @Input() fill = "outline";
  @Input() mode?: string;
  @Input() disabled = false;
  @Input() options: google.maps.places.AutocompleteOptions = {
    types: ["address"],
    componentRestrictions: { country: "UK" },
  };

  @Input() showError = false;
  @Input() errorText = "Please select an address";

  private apiLoaded: Observable<boolean>;
  private autocomplete!: google.maps.places.Autocomplete;
  private inputElement: HTMLInputElement | null = null;

  constructor(
    private googlePlacesService: GooglePlacesService,
    private ngZone: NgZone,
    private core: CoreService,
  ) {
    this.apiLoaded = this.googlePlacesService.apiLoaded;
  }

  async ngAfterViewInit() {
    this.inputElement = await this.searchInput.getInputElement();

    this.apiLoaded.subscribe(loaded => {
      if (!loaded) {
        this.core.errorToast("Address search unavailable. Please enter manually.", 4000);
        return;
      }

      try {
        const options: google.maps.places.AutocompleteOptions = {
          ...this.options,
          fields: ["address_components", "formatted_address", "place_id", "geometry", "types"],
        };

        this.autocomplete = new google.maps.places.Autocomplete(this.inputElement, options);

        this.autocomplete.addListener("place_changed", () => {
          this.ngZone.run(() => {
            const place = this.autocomplete.getPlace();
            if (!place.place_id) {
              this.core.errorToast("No matching addresses found. Please try a different search.", 4000);
              return;
            }

            this.placeChanged.emit({
              address: place.formatted_address || "",
              placeId: place.place_id,
              details: place,
            });
          });
        });
      } catch (error) {
        this.core.errorToast("Address search unavailable. Please enter manually.", 4000);
      }
    });
  }

  clearInput() {
    if (this.inputElement) {
      this.inputElement.value = "";
    }
  }
}
