import { Component, HostListener, NgZone, OnInit, ViewChild } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import { Capacitor, PluginListenerHandle } from "@capacitor/core";
import { SplashScreen } from "@capacitor/splash-screen";
import { AlertController, Platform, ToastController } from "@ionic/angular";
import { take } from "rxjs/operators";

import { Browser } from "@capacitor/browser";
import { ConnectionStatus, Network } from "@capacitor/network";
import { Subscription } from "rxjs";
import { environment } from "../environments/environment";
import { AccountService } from "./account/account.service";
import { CoreService } from "./shared/core/core.service";
import { AuthenticationService } from "./shared/auth/authentication-service";
import { PosthogService } from "./shared/core/posthog.service";
import { App, URLOpenListenerEvent } from "@capacitor/app";
import { IonSelect } from "@ionic/angular";
import { UserHolding } from "./account/account.model";

import { register } from "swiper/element/bundle";
register();

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
  private _loggedInObserver: Subscription = new Subscription();
  private _routerSubscription: Subscription;
  private _holdingsListener: Subscription;
  private _holdingListener: Subscription;

  isDesktop: boolean;
  appVersion: string;
  isMobile = false;
  isIOS = false;
  flockFinderOnline = environment.WEBSITE_ADDRESS;
  networkStatus: ConnectionStatus;
  networkListener: Promise<PluginListenerHandle>;

  public flockImg = "/assets/icon/white/sheep-white.svg";
  public flockImgActive = "/assets/icon/success/sheep_success.svg";

  // Holding selection properties
  selectedHolding: UserHolding;
  userHoldings: UserHolding[] = [];
  customPopoverOptions: any = {
    header: "Select Holding",
    message: "To edit holdings please go to your account settings",
  };
  @ViewChild("holdingSelect") holdingSelect: IonSelect;

  // Menu sections
  isMoreExpanded = false;
  isAccountExpanded = false;

  constructor(
    private platform: Platform,
    private authService: AuthenticationService,
    private router: Router,
    private core: CoreService,
    private accountService: AccountService,
    private alertController: AlertController,
    private toastController: ToastController,
    private posthogService: PosthogService,
    private zone: NgZone,
  ) {
    this.initializeApp();
    this.core.isDesktopView().subscribe(isDesktop => {
      this.isDesktop = isDesktop;
    });
    this.appVersion = environment.version;

    if (this.platform.is("mobile")) {
      this.isMobile = true;
    }
    if (this.platform.is("ios") && !this.platform.is("mobileweb")) {
      this.isIOS = true;
    }
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (Capacitor.isPluginAvailable("SplashScreen")) {
        SplashScreen.hide();
      }
      this.core.onResize(this.platform.width());
      // Add PostHog app_launched event
      this.posthogService.capture("app_launched", {
        version: environment.version,
        platform: Capacitor.getPlatform(),
      });
    });
    App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
      console.log("App opened with URL: " + event.url);
      this.zone.run(() => {
        const slug = event.url.split(".co.uk").pop();
        if (slug) {
          this.router.navigateByUrl(slug);
        }
      });
    });
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.core.onResize(event.target.innerWidth);
  }

  navigateTo(url: string) {
    this.router.navigateByUrl(url);
  }

  onLogout() {
    this.authService.SignOut();
  }

  goToFlockFinderWeb() {
    this.openLink(this.flockFinderOnline);
  }

  async goToAsk() {
    await this.core.goToAsk();
  }

  goToHome() {
    this.router.navigateByUrl("/manage/home");
  }

  async goToHelp() {
    await this.core.goToHelp();
  }

  toggleMoreSection() {
    this.isMoreExpanded = !this.isMoreExpanded;

    // Close account section when opening more section
    if (this.isMoreExpanded) {
      this.isAccountExpanded = false;
    }
  }

  toggleAccountSection() {
    this.isAccountExpanded = !this.isAccountExpanded;

    // Close more section when opening account section
    if (this.isAccountExpanded) {
      this.isMoreExpanded = false;
    }
  }

  isRouteActive(route: string): boolean {
    if (route === "home") {
      return this.router.url.includes("/manage/home");
    } else if (route === "fields") {
      return this.router.url.includes("/manage/fields");
    } else if (route === "flock") {
      return this.router.url.includes("/manage/flock");
    } else if (route === "medical") {
      return (
        this.router.url.includes("/manage/medical") &&
        !this.router.url.includes("/manage/medical/medical-cabinet") &&
        !this.router.url.includes("/manage/medical/suppliers")
      );
    } else if (route === "medical-cabinet") {
      return this.router.url.includes("/manage/medical/medical-cabinet");
    } else if (route === "suppliers") {
      return this.router.url.includes("/manage/medical/suppliers");
    } else if (route === "movement-history") {
      return this.router.url.includes("/manage/movement-history");
    } else if (route === "labels") {
      return this.router.url.includes("/manage/labels");
    } else if (route === "data") {
      return this.router.url.includes("/manage/data");
    } else if (route === "account") {
      return this.router.url.includes("/account");
    } else if (route === "holdings") {
      return this.router.url.includes("/manage/holdings");
    }
    return false;
  }

  checkAndExpandSections() {
    // Check if any routes in the "More" section are active
    if (
      this.isRouteActive("medical-cabinet") ||
      this.isRouteActive("suppliers") ||
      this.isRouteActive("movement-history") ||
      this.isRouteActive("labels") ||
      this.isRouteActive("data")
    ) {
      this.isMoreExpanded = true;
    }

    // Check if any routes in the "Account" section are active
    if (this.isRouteActive("holdings") || this.isRouteActive("account")) {
      this.isAccountExpanded = true;
    }
  }

  navManage(url: string) {
    this.accountService.selectedUserHoldingGlobal.pipe(take(1)).subscribe(selectedHolding => {
      if (selectedHolding !== null) {
        let navigationExtras: NavigationExtras = {
          state: {
            sourcePage: this.router.url,
            selectedHolding: selectedHolding,
          },
        };
        this.router.navigate([url], navigationExtras);
      } else {
        this.noHoldingSelected();
      }
    });
  }

  noHoldingSelected() {
    this.accountService.userHoldings.pipe(take(1)).subscribe(userHoldings => {
      if (userHoldings.length === 0) {
        this.goToHoldingsAlert();
      } else {
        this.selectHoldingToast();
      }
    });
  }

  async goToHoldingsAlert() {
    const alert = await this.alertController.create({
      message: "To continue you must add a holding to your account",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: "Okay",
          handler: () => {
            this.router.navigateByUrl("account");
          },
        },
      ],
    });
    await alert.present();
  }

  async selectHoldingToast() {
    const toast = await this.toastController.create({
      message: "Select holding from dropdown.",
      duration: 2000,
      position: "top",
    });
    toast.present();
  }

  public async noNetworkToast(present = true, message = "", offline = false) {
    let buttons = undefined;
    let duration = 3000;
    if (offline === true) {
      buttons = [
        {
          text: "Hide",
          role: "cancel",
        },
      ];
      duration = 3000;
    }
    const toast = await this.toastController.create({
      message: message,
      color: "light",
      buttons: buttons,
      duration: duration,
    });
    if (present) toast.present();
  }

  async openLink(url: string) {
    await Browser.open({ url: url, presentationStyle: "popover" });
  }

  storeHoldingSelection(event: CustomEvent) {
    const selectedHoldingId = event.detail.value;
    this.selectedHolding = this.userHoldings.find(holding => holding.id === selectedHoldingId);
    if (this.selectedHolding) {
      this.accountService.updateSelectedHolding(this.selectedHolding);
    }
  }

  openHoldingSelect() {
    if (this.holdingSelect) {
      this.holdingSelect.open();
    }
  }

  ngOnInit() {
    // pre-load js element so can use it when offline
    this.noNetworkToast(false);

    // tracking network status changes
    this.networkListener = Network.addListener("networkStatusChange", status => {
      this.networkStatus = status;
      if (status.connectionType == "none") this.noNetworkToast(true, "No internet connection!", true);
    });

    // Check if sections should be expanded based on current route
    this.checkAndExpandSections();

    // Listen for route changes to auto-expand relevant sections
    this._routerSubscription = this.router.events.subscribe(() => {
      this.checkAndExpandSections();
    });

    // Initialize holdings data
    this._holdingsListener = this.accountService.userHoldings.subscribe(_userHoldings => {
      // restrict dropdown to only those with permissionGranted
      this.userHoldings = _userHoldings.filter(holding => {
        return holding.permissionGranted === true;
      });
    });

    this._holdingListener = this.accountService.selectedUserHoldingGlobal.subscribe(holding => {
      this.selectedHolding = holding;
    });

    // Get initial network status
    Network.getStatus().then(status => {
      this.networkStatus = status;
    });
  }

  ngOnDestroy() {
    this._loggedInObserver.unsubscribe();
    if (this._routerSubscription) {
      this._routerSubscription.unsubscribe();
    }
    if (this._holdingsListener) {
      this._holdingsListener.unsubscribe();
    }
    if (this._holdingListener) {
      this._holdingListener.unsubscribe();
    }
    Network.removeAllListeners();
  }
}
