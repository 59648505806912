<div class="address-input-container">
  <ion-input
    #searchInput
    [placeholder]="placeholder"
    [clearInput]="true"
    [disabled]="disabled"
    [fill]="fill"
    [mode]="mode"
    [class.error]="showError"
  ></ion-input>

  <div *ngIf="showError" class="error-message">
    <ion-icon name="warning" slot="start"></ion-icon>
    {{ errorText }}
  </div>
</div>
