import { Injectable } from "@angular/core";
import { CanActivate, Router, UrlTree } from "@angular/router";
import { AccountService } from "../../account/account.service";
import { Observable, of } from "rxjs";
import { map, switchMap, catchError } from "rxjs/operators";
import { AuthenticationService } from "../auth/authentication-service";
import { SignupStateService } from "src/app/welcome/services/signup-state.service";

@Injectable({
  providedIn: "root",
})
export class ProfileCompleteGuard implements CanActivate {
  constructor(
    private accountService: AccountService,
    private router: Router,
    private authService: AuthenticationService,
    private signupState: SignupStateService,
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return of(this.authService.isLoggedIn).pipe(
      switchMap(isLoggedIn => {
        if (!isLoggedIn) return of(true); // Let AuthGuard handle authentication
        return this.accountService.getProfile().pipe(
          switchMap(profile => {
            return this.accountService.getUserHoldings().pipe(
              map(holdings => {
                const filteredHoldings = holdings.filter(holding => holding.holdingName !== "FL/OCK/DEMO");
                if (filteredHoldings.length === 0) {
                  if (!profile?.firstName) {
                    this.signupState.updateState({
                      currentStep: 3,
                    });
                    return this.router.parseUrl("/welcome/signup/user-details");
                  }
                  this.signupState.updateState({
                    currentStep: 4,
                  });
                  return this.router.parseUrl("/welcome/signup/holding-name");
                }
                return true;
              }),
              catchError(() => of(true)),
            );
          }),
          catchError(() => of(true)),
        );
      }),
    );
  }
}
