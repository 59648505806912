import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { SwitchComponent } from "./switch/switch.component";
import { AddressAutocompleteComponent } from "./address-autocomplete/address-autocomplete.component";

@NgModule({
  imports: [CommonModule, IonicModule, FormsModule, ReactiveFormsModule],
  declarations: [SwitchComponent, AddressAutocompleteComponent],
  exports: [SwitchComponent, AddressAutocompleteComponent],
})
export class InputComponentsModule {}
