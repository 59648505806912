export class Profile {
  constructor(
    public firstName: string,
    public lastName: string,
    public emailAddress?: string,
    public is_confirmed?: boolean,
    public phoneNumber?: string,
    public address?: {
      raw: string;
      street_number?: string;
      route?: string;
      locality?: string;
    },
    public referralCode?: string,
  ) {}
}

export class UserHolding {
  constructor(
    public id: number,
    public ownerType: "owner" | "contributor",
    public user_id: number,
    public holding: number,
    public holdingName: string,
    public cphNumber: string | null,
    public userPaid: boolean,
    public cancellationDate: Date,
    public createdAt?: Date,
    public requestedExtensionEndDate?: Date,
    public firstFlockNumber?: string,
    public defaultFlockNumber?: string,
    public lambingDate?: {
      week: number;
      month: number;
    },
    public latestVanNumber?: string,
    public holdingAddress?: {
      raw: string;
      street_number?: string;
      route?: string;
      locality?: string;
    },
    public permissionPending?: boolean | null,
    public permissionGranted?: boolean | null,
    public ownerName?: string,
    public ownerEmail?: string,
    public holdingLocation?: {
      country: string;
      id: number;
      latitude: number;
      locality: string;
      longitude: number;
      name: string | null;
      place_id: string;
      postal_code: string;
      street_name: string;
      street_number: string;
      raw: string;
    },
  ) {}

  static fromApiResponse(data: UserHolding): UserHolding {
    return new UserHolding(
      data.id,
      data.ownerType,
      data.user_id,
      data.holding,
      data.holdingName,
      data.cphNumber,
      data.userPaid,
      new Date(data.cancellationDate),
      data.createdAt ? new Date(data.createdAt) : undefined,
      data.requestedExtensionEndDate ? new Date(data.requestedExtensionEndDate) : undefined,
      data.firstFlockNumber,
      data.defaultFlockNumber,
      data.lambingDate,
      data.latestVanNumber,
      data.holdingAddress,
      data.permissionPending,
      data.permissionGranted,
      data.ownerName,
      data.ownerEmail,
      data.holdingLocation,
    );
  }

  static fromJSON(json: any): UserHolding {
    return new UserHolding(
      json.id,
      json.ownerType,
      json.user_id,
      json.holding,
      json.holdingName,
      json.cphNumber,
      json.userPaid,
      new Date(json.cancellationDate),
      json.createdAt ? new Date(json.createdAt) : undefined,
      json.requestedExtensionEndDate ? new Date(json.requestedExtensionEndDate) : undefined,
      json.firstFlockNumber,
      json.defaultFlockNumber,
      json.lambingDate,
      json.latestVanNumber,
      json.holdingAddress,
      json.permissionPending,
      json.permissionGranted,
      json.ownerName,
      json.ownerEmail,
      json.holdingLocation,
    );
  }

  toJSON(): any {
    return {
      id: this.id,
      ownerType: this.ownerType,
      user_id: this.user_id,
      holding: this.holding,
      holdingName: this.holdingName,
      cphNumber: this.cphNumber,
      firstFlockNumber: this.firstFlockNumber,
      latestVanNumber: this.latestVanNumber,
      holdingAddress: this.holdingAddress,
      userPaid: this.userPaid,
      cancellationDate: this.cancellationDate,
      createdAt: this.createdAt,
      lambingDate: this.lambingDate,
      holdingLocation: this.holdingLocation,
    };
  }
}

export type UserHoldingType = {
  id: number;
  ownerType: string;
  user_id: number;
  holding: number;
  holdingName: string;
  cphNumber: string | null;
  userPaid: boolean;
  cancellationDate: Date;
  createdAt?: Date;
  requestedExtensionEndDate?: Date;
  firstFlockNumber?: string;
  lambingDate?: {
    week: number;
    month: number;
  };
  latestVanNumber?: string;
  holdingAddress?: {
    raw: string;
    street_number?: string;
    route?: string;
    locality?: string;
  };
  permissionPending?: boolean | null;
  permissionGranted?: boolean | null;
  ownerName?: string;
  ownerEmail?: string;
  holdingLocation?: {
    country: string;
    id: number;
    latitude: number;
    locality: string;
    longitude: number;
    name: string | null;
    place_id: string;
    postal_code: string;
    street_name: string;
    street_number: string;
    raw: string;
  };
};

export class HoldingPermissions {
  constructor(
    public permissionPending: boolean | null,
    public permissionGranted: boolean | null,
  ) {}
}

export class HoldingNew {
  constructor(
    public holdingName: string,
    public cphNumber?: string | null,
    public user_holdings?: [{ ownerType: string }],
    public defaultFlockNumber?: string,
    public flock_numbers?: [{ flockNumber: string }],
    public userPaid?: boolean,
    public requestedExtensionEndDate?: Date,
    public address?: {
      raw: string;
      street_number?: string;
      route?: string;
      locality?: string;
    },
    public van_numbers?: [{ vanNumber: string }],
    public lambingDate?: {
      week: number;
      month: number;
    },
  ) {}
}

export class AllHoldings {
  constructor(
    public id: number,
    public holdingName: string,
    public confirmed: boolean,
    public user_holdings: [
      {
        id: number;
        ownerType: string;
        user_id: number;
        holding: number;
        holdingName: string;
        userPaid: boolean;
        cancellationDate: Date;
        createdAt?: Date;
      },
    ],
    public flock_numbers?: [
      {
        flockNumber: string;
      },
    ],
  ) {}
}

export class GetHolding {
  constructor(
    public id: number,
    public holdingName: string,
    public cphNumber: string | null,
  ) {}
}

export class UserMembership {
  constructor(
    public isMember: boolean,
    public wasMember: boolean,
    public planDescription: string,
    public planName: string,
    public productType: string, // "askflockfinder" or "flockfinder"
    public cancellationDatetime?: Date,
    public isTrial?: boolean,
    public trialEnd?: Date,
  ) {}
}

export class ReferralInfo {
  constructor(
    public referralAmount: number,
    public refereeAmount: number,
    public allowReferrals: boolean,
  ) {}
}

export const OwnershipLimits = {
  non_member: 1,
  shepherd_monthly: 1,
  farm_monthly: 1,
  estate_monthly: 3,
  shepherd_yearly: 1,
  farm_yearly: 1,
  estate_yearly: 3,
};

export class VanGuidance {
  constructor(
    public name: string,
    public shortDescription: string,
    public moreInfoUrl: string,
    public faviconUrl?: string,
  ) {}
}
