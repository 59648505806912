import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss']
})
export class SwitchComponent implements OnInit {
  @Input() option1: { title: string, value: any };
  @Input() option2: { title: string, value: any };
  @Input() initial: any;
  @Output() onChange = new EventEmitter<any>();

  currentValue: any = false;

  constructor() { }

  private get _result() {
    return this.currentValue ? this.option1.value : this.option2.value;
  }

  ngOnInit() {
    this.currentValue = this.option1.value == this.initial;
    this.onChange.emit(this._result);
  }

  changeValue(event: any) {
    this.onChange.emit(this._result);
  }
}
