import { HttpInterceptorFn, HttpRequest, HttpHandlerFn, HttpErrorResponse } from "@angular/common/http";
import { inject } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { throwError } from "rxjs";
import { catchError, map, switchMap, take } from "rxjs/operators";
import { AuthenticationService } from "./authentication-service";
import { AccountService } from "src/app/account/account.service";

const urlsToNotUse = [
  "auth/token/refresh",
  "auth/register",
  "auth/token",
  "auth/emailtoken/.",
  "auth/reset-password",
  "auth/reset-password/.",
  "error/report",
];

export const AuthInterceptorService: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const ngFireAuth = inject(AngularFireAuth);
  const authService = inject(AuthenticationService);
  const accountService = inject(AccountService);

  // Add direct check for invitation endpoints before the main interceptor logic
  if (req.url.includes("/api/v1/invitations/") && (req.url.includes("/accept/") || req.url.endsWith("/"))) {
    return next(req);
  }

  if (isValidRequestForInterceptor(req.url)) {
    return ngFireAuth.idToken.pipe(
      take(1),
      switchMap(token => {
        if (token === null) {
          console.log("No token available");
          authService.SignOut();
          return throwError(() => "No token available");
        }
        return addTokenAndHoldingId(req, token, accountService).pipe(
          switchMap(clonedReq => {
            return next(clonedReq).pipe(
              catchError(error => {
                if (error instanceof HttpErrorResponse && error.status === 401) {
                  return throwError(() => error);
                }
                return throwError(() => error);
              }),
            );
          }),
        );
      }),
    );
  }

  return next(req);
};

// Helper functions moved outside
function isValidRequestForInterceptor(requestUrl: string): boolean {
  // First check if it's an Azure Blob Storage URL
  if (requestUrl.includes("blob.core.windows.net")) {
    return false;
  }

  // Then check the API paths
  const positionIndicator = "api/";
  const position = requestUrl.indexOf(positionIndicator);
  if (position > 0) {
    const destination = requestUrl.substr(position + positionIndicator.length);
    for (const address of urlsToNotUse) {
      if (new RegExp(address).test(destination)) {
        return false;
      }
    }
  }
  return true;
}

function addTokenAndHoldingId(req: HttpRequest<unknown>, token: string, accountService: AccountService) {
  return accountService.selectedUserHoldingGlobal.pipe(
    take(1),
    map(userHolding => {
      const headers: Record<string, string> = {
        Authorization: token,
      };
      if (userHolding?.holding) {
        headers["Selected-Holding-ID"] = userHolding.holding.toString();
      }
      return req.clone({ setHeaders: headers });
    }),
  );
}
