import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { HammerModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { HttpClientJsonpModule } from "@angular/common/http";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { provideHttpClient, withInterceptors, withJsonpSupport } from "@angular/common/http";

import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CoreService } from "./shared/core/core.service";
import { AuthInterceptorService } from "./shared/auth/auth-interceptor.service";
import { ErrorInterceptor } from "./shared/auth/error-interceptor.service";

import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { PosthogService } from "./shared/core/posthog.service";
import { ReactiveFormsModule } from "@angular/forms";
import { PageTrackingDirective } from "./shared/directives/page-tracking.directive";
import { BluetoothService } from "./shared/core/bluetooth.service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { provideLottieOptions } from "ngx-lottie";
import player from "lottie-web";
import { HeaderModule } from "./components/manage/header/header.module";

import { NgxDatatableModule } from "@swimlane/ngx-datatable";
@NgModule({
  declarations: [AppComponent, PageTrackingDirective],
  imports: [
    // BrowserModule,
    BrowserAnimationsModule,
    HammerModule,
    IonicModule.forRoot({ mode: "ios", innerHTMLTemplatesEnabled: true }),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    ReactiveFormsModule,
    HttpClientJsonpModule,
    HeaderModule,
    NgxDatatableModule,
  ],
  providers: [
    CoreService,
    BluetoothService,
    PosthogService,
    provideHttpClient(withInterceptors([AuthInterceptorService, ErrorInterceptor]), withJsonpSupport()),
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    provideLottieOptions({ player: () => player }),
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
