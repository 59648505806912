import { HeaderComponent } from "./header.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import { RouterModule } from "@angular/router";
import { InputComponentsModule } from "../../inputs/components.module";

@NgModule({
  imports: [CommonModule, IonicModule, RouterModule, InputComponentsModule],
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
})
export class HeaderModule {}
