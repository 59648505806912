<div class="switch-container">
  <ion-text color="light">{{ option2.title }}</ion-text
  >&nbsp;
  <ion-toggle
    class="area-measure-switch"
    color="tertiary"
    [(ngModel)]="currentValue"
    (ionChange)="changeValue($event)"
  ></ion-toggle>
  &nbsp;<ion-text color="light">{{ option1.title }}</ion-text>
</div>
