import { Injectable } from "@angular/core";
import { User } from "./user";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { Preferences } from "@capacitor/preferences";
import { NavController } from "@ionic/angular";
import { CurrentPlatformService } from "../services/current-platform.service";
import { AccountService } from "src/app/account/account.service";
import { PushNotificationService } from "../notifications/push-notification.service";
import { catchError, from, Observable, of, switchMap } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  userData: any;
  authServiceUrl = environment.FLOCKFINDER_API_URL;
  constructor(
    private ngFireAuth: AngularFireAuth,
    private navCtrl: NavController,
    private pushNotificationService: PushNotificationService,
    private http: HttpClient,
    private cookieService: CookieService,
    private currentPlatformService: CurrentPlatformService,
    private accountService: AccountService,
  ) {
    this.ngFireAuth.authState.subscribe(user => {
      if (user) {
        this.userData = user;
        localStorage.setItem("user", JSON.stringify(this.userData));
        JSON.parse(localStorage.getItem("user"));
      } else {
        localStorage.setItem("user", null);
        JSON.parse(localStorage.getItem("user"));
      }
    });
  }

  get user(): User {
    const user = JSON.parse(localStorage.getItem("user"));
    return user;
  }

  // Login in with email/password
  SignIn(email: string, password: string): Promise<any> {
    return this.ngFireAuth.signInWithEmailAndPassword(email, password).then(result => {
      return result;
    });
  }

  // Register user with email/password
  RegisterUser(email, password) {
    return this.ngFireAuth.createUserWithEmailAndPassword(email, password);
  }

  private getActionCodeSettingsWithInvite(actionCodeSettings?: any): any {
    const pendingInviteCode = localStorage.getItem("pendingInviteCode");
    if (!pendingInviteCode || !actionCodeSettings?.url) {
      return actionCodeSettings;
    }

    const url = new URL(actionCodeSettings.url);
    url.searchParams.append("inviteCode", pendingInviteCode);
    return {
      ...actionCodeSettings,
      url: url.toString(),
    };
  }

  // Email verification when new user register
  SendVerificationMail(actionCodeSettings?: any) {
    const settings = this.getActionCodeSettingsWithInvite(actionCodeSettings);
    return this.ngFireAuth.currentUser.then(u =>
      u.sendEmailVerification(settings).catch(error => {
        throw error;
      }),
    );
  }

  // Recover password
  async PasswordRecover(passwordResetEmail: string, actionCodeSettings?: any) {
    try {
      const settings = this.getActionCodeSettingsWithInvite(actionCodeSettings);
      await this.ngFireAuth.sendPasswordResetEmail(passwordResetEmail, settings);
    } catch (error) {
      throw error;
    }
  }

  // update the user in local storage, for example, once email is verified
  updateUserInLocalStorage(user: User) {
    localStorage.setItem("user", JSON.stringify(user));
  }

  // Returns true when user is logged in
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem("user"));
    return user !== null && user.emailVerified !== false ? true : false;
  }

  // Returns true when user's email is verified
  get isEmailVerified(): boolean {
    const user = JSON.parse(localStorage.getItem("user"));
    return user.emailVerified !== false ? true : false;
  }

  // Sign-out
  async SignOut() {
    try {
      this.clearCookies();
      await Preferences.remove({ key: "selectedHolding" });
      await localStorage.removeItem("user");
      await this.pushNotificationService.softClearToken();
      this.accountService.clearSelectedHolding();
      const isBrowser: boolean = this.currentPlatformService.isBrowser();
      return await this.ngFireAuth.signOut().then(() => {
        if (isBrowser) {
          this.navCtrl.navigateRoot("welcome");
        } else {
          this.navCtrl.navigateRoot("slides");
        }
      });
    } catch (error) {
      console.error("Error during sign out:", error);
    }
  }

  clearCookies() {
    this.cookieService.delete("token");
  }

  signInUsingExchangeToken(token: string): Observable<any> {
    return this.http
      .get(`${this.authServiceUrl}/api/auth/token-exchange/`, {
        headers: { Authorization: token },
        withCredentials: true,
      })
      .pipe(
        switchMap((response: any) => {
          if (response && response.token) {
            return from(this.SignInWithToken(response.token));
          } else {
            console.error("Invalid token response");
            return of(null);
          }
        }),
        catchError(error => {
          console.error("Token exchange failed", error);
          return of(null);
        }),
      );
  }

  // Login with custom token
  SignInWithToken(token: string): Promise<any> {
    return this.ngFireAuth
      .signInWithCustomToken(token)
      .then(result => {
        return result;
      })
      .catch(error => {
        throw error;
      });
  }
}
