import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";

type CurrentPlatform = "browser" | "apple" | "android";

@Injectable({
  providedIn: "root",
})
export class CurrentPlatformService {
  private _currentPlatform: CurrentPlatform;

  constructor(private platform: Platform) {
    this.setCurrentPlatform();
  }

  get currentPlatform() {
    return this._currentPlatform;
  }

  isApple() {
    return this._currentPlatform === "apple";
  }
  isNative() {
    return this.isApple() || this.isAndroid();
  }
  isAndroid() {
    return this._currentPlatform === "android";
  }
  isBrowser() {
    return this._currentPlatform === "browser";
  }

  isMobile() {
    return this.platform.is("mobile");
  }

  // iphone app
  // "iphone", "ios", "cordova", "capacitor", "mobile", "hybrid"

  // iphone browser
  // "iphone", "ios", "phablet", "mobile", "mobileweb"

  // android app
  // "android", "phablet", "cordova", "capacitor", "desktop", "hybrid"

  // android browser
  // ??

  private setCurrentPlatform() {
    // Are we on mobile platform? Yes if platform is ios or android, but not desktop or mobileweb, no otherwise
    if (
      (this.platform.is("iphone") && this.platform.is("capacitor")) ||
      (this.platform.is("ipad") && this.platform.is("capacitor"))
    ) {
      this._currentPlatform = "apple";
    } else if (this.platform.is("android") && this.platform.is("capacitor")) {
      this._currentPlatform = "android";
    } else {
      this._currentPlatform = "browser";
    }
  }
}
