import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./shared/guards/auth.guard";
import { ProfileCompleteGuard } from "./shared/guards/profile-complete.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "manage/home",
    pathMatch: "full",
  },
  {
    path: "welcome",
    loadChildren: () => import("./welcome/welcome.module").then(m => m.WelcomePageModule),
  },
  {
    path: "manage",
    loadChildren: () => import("./manage/manage.module").then(m => m.ManagePageModule),
    canActivate: [AuthGuard, ProfileCompleteGuard],
  },
  {
    path: "account",
    loadChildren: () => import("./account/account.module").then(m => m.AccountPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "payments/success",
    loadChildren: () => import("./payments/success/success.module").then(m => m.SuccessPageModule),
  },
  {
    path: "payments/failure",
    loadChildren: () => import("./payments/failure/failure.module").then(m => m.FailurePageModule),
  },
  {
    path: "payments/billing-updated",
    loadChildren: () =>
      import("./payments/billing-updated/billing-updated.module").then(m => m.BillingUpdatedPageModule),
  },
  {
    path: "slides",
    loadChildren: () => import("./welcome/slides/slides.module").then(m => m.SlidesPageModule),
  },
  {
    path: "error",
    loadChildren: () => import("./error/error.module").then(m => m.ErrorPageModule),
  },

  // any other path, redirect home
  {
    path: "**",
    redirectTo: "manage/home",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
