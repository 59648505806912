import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { CoreService } from "../core/core.service";
import { Observable, of } from "rxjs";
import { map, catchError, shareReplay } from "rxjs/operators";

import { environment } from "src/environments/environment";

declare global {
  interface Window {
    google: typeof google;
  }
}

@Injectable({
  providedIn: "root",
})
export class GooglePlacesService {
  private readonly API_URL = "https://maps.googleapis.com/maps/api/js";
  apiLoaded: Observable<boolean>;

  constructor(
    httpClient: HttpClient,
    private core: CoreService,
  ) {
    // Set up global error handler for auth failures
    (window as any).gm_authFailure = () => {
      this.core.errorToast("Sorry, something went wrong. Please add address manually.", 5000);
    };

    // Intercept console errors for Maps API errors
    this.interceptConsoleErrors();

    this.apiLoaded = httpClient.jsonp(`${this.API_URL}?key=${environment.placesAPI}&libraries=places`, "callback").pipe(
      map(() => true),
      catchError(() => {
        this.core.errorToast("Sorry, something went wrong. Please add address manually.", 5000);
        return of(false);
      }),
      shareReplay(1),
    );
  }

  private interceptConsoleErrors() {
    const originalError = console.error;
    console.error = (...args) => {
      // Check for Google Maps API errors
      const errorMessage = args[0]?.toString() || "";
      if (errorMessage.includes("Google Maps JavaScript API error")) {
        this.core.errorToast("Sorry, something went wrong. Please add address manually.", 5000);
      }

      // Call original console.error
      originalError.apply(console, args);
    };
  }
}
