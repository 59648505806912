<ion-toolbar
  class="flock-toolbar flock-toolbar-top"
  [class.demo-toolbar]="isDemoHoldingGetter"
  [class.transparent]="isTransparent"
>
  <!-- Mobile -->
  <div class="header-content">
    <ion-buttons slot="start" *ngIf="!isDesktop">
      <!-- Circular button replacing the logo -->
      <div class="holding-avatar-container" *ngIf="selectedHolding && !isLoading">
        <div class="holding-avatar" (click)="toggleHoldingInfo($event)">
          {{ selectedHolding.holdingName.charAt(0).toUpperCase() }}
        </div>
      </div>
    </ion-buttons>

    <div class="action-container">
      <ng-container *ngIf="!isDesktop">
        <ion-button
          *ngIf="sort"
          color="light"
          fill="clear"
          class="ion-no-padding"
          (click)="sort($event)"
          [disabled]="dataLoaded"
        >
          <ion-icon color="light" size="large" slot="icon-only" name="swap-vertical-outline"></ion-icon>
        </ion-button>
      </ng-container>
      <ion-button
        *ngIf="filter"
        color="light"
        fill="clear"
        class="ion-no-padding"
        (click)="filter()"
        [disabled]="dataLoaded"
      >
        <ion-icon color="light" size="large" slot="icon-only" name="funnel-outline"></ion-icon>
      </ion-button>

      <ion-button
        *ngIf="refresh"
        color="light"
        fill="clear"
        class="ion-no-padding"
        (click)="refresh()"
        [disabled]="dataLoaded"
      >
        <ion-icon color="light" size="large" slot="icon-only" name="refresh-outline"></ion-icon>
      </ion-button>
      <ng-container *ngIf="isDesktop && sort">
        <ion-button color="light" fill="clear" class="ion-no-padding" (click)="sort($event)">
          <ion-icon color="light" size="large" slot="icon-only" name="swap-vertical-outline"></ion-icon>
        </ion-button>
      </ng-container>
    </div>
  </div>
</ion-toolbar>

<!-- Add overlay before the popup -->
<div class="popup-overlay" *ngIf="showHoldingInfo" (click)="closeHoldingInfo()"></div>

<div
  class="holding-info-popup"
  *ngIf="showHoldingInfo"
  [style.top.px]="popupTop"
  [style.left.px]="popupLeft"
  [ngClass]="{ closing: false }"
>
  <div class="holding-info-content">
    <!-- Green rounded button with holding name and arrow -->
    <button class="holding-info-button" (click)="openHoldingSelect()">
      <span class="holding-name">
        {{
          selectedHolding.holdingName.length > 13
            ? (selectedHolding.holdingName | slice: 0 : 13) + "..."
            : selectedHolding.holdingName
        }}
      </span>
      <ion-icon name="chevron-down-circle-outline"></ion-icon>
    </button>
    <!-- Hidden select element that will be triggered by the button -->
    <ion-select
      #holdingSelect
      *ngIf="userHoldings.length > 0"
      [interfaceOptions]="customPopoverOptions"
      placeholder="select holding"
      (ionChange)="storeHoldingSelectionAndClosePopup($event)"
      [value]="selectedHolding?.id"
      style="display: none"
    >
      <ion-select-option *ngFor="let holding of userHoldings" [value]="holding.id">
        {{ holding.holdingName.length > 13 ? (holding.holdingName | slice: 0 : 13) + "..." : holding.holdingName }}
      </ion-select-option>
    </ion-select>

    <!-- Navigation list with icons -->
    <div class="nav-list">
      <div class="nav-item" (click)="navigateTo('/manage/holdings')">
        <ion-icon name="home-outline"></ion-icon>
        <span>My Farms</span>
      </div>
      <div class="nav-item" (click)="navigateTo('account')">
        <ion-icon name="person-outline"></ion-icon>
        <span>Account</span>
      </div>
      <div class="nav-item" (click)="onLogout()">
        <ion-icon name="log-out-outline"></ion-icon>
        <span>Log out</span>
      </div>
      <div class="nav-item" (click)="goToHelp()">
        <ion-icon name="help-circle-outline"></ion-icon>
        <span>Help</span>
      </div>
    </div>

    <!-- Suggestion text -->
    <div class="suggestion-text">
      <p>
        Got a suggestion? Let us know at:<br />
        <a href="mailto:hello&#64;flockfinder.co.uk">hello&#64;flockfinder.co.uk</a>
        <br />
        <span class="version-text">v{{ appVersion }}</span>
      </p>
    </div>
  </div>
</div>
