export const environment = {
  production: false,
  version: "2.16.0",
  FLOCKFINDER_URL_PAYMENT: "https://web-dev.flockfinder.co.uk/plans/",
  FLOCKFINDER_URL_ACCOUNT: "https://web-dev.flockfinder.co.uk/account/",
  ASK_FLOCKFINDER: "https://ask-dev.flockfinder.co.uk",
  // ASK_FLOCKFINDER: "http://localhost:5173",
  FLOCKFINDER_API_URL: "https://api-dev.flockfinder.co.uk",
  WEBSITE_ADDRESS: "https://app-dev.flockfinder.co.uk",
  mapsKey: "AIzaSyB9Xt0V7zWmVLGZ_XiFJlNo78WUBYfK_GA",
  placesAPI: "AIzaSyA-yoWaeOmeV5DyP_gRJibSl12VbFphMK",
  firebaseConfig: {
    apiKey: "AIzaSyDKqYbYUdWl5GKXBLL70NBSftvZIBvrfvE",
    authDomain: "flockfinder-dev.firebaseapp.com",
    projectId: "flockfinder-dev",
    storageBucket: "flockfinder-dev.appspot.com",
    messagingSenderId: "708900876297",
    appId: "1:708900876297:web:76e809e8036a5a1e2688d8",
    measurementId: "G-GQ69BF4XE6",
  },
  posthog: {
    apiKey: "phc_9Ri3h1s1T8yHFcSWLZJnD31jKANAL0vVhRluVnnVtdA",
    host: "https://eu.i.posthog.com",
  },
};
