import { Injectable, NgZone } from "@angular/core";
import { BleClient, ScanResult } from "@capacitor-community/bluetooth-le";
import { BehaviorSubject, firstValueFrom } from "rxjs";
import { debounceTime, take } from "rxjs/operators";
import { CoreService } from "./core.service";
import { AlertController, LoadingController } from "@ionic/angular";
import { Haptics } from "@capacitor/haptics";
import { EidTagData } from "../../manage/flock/metric-session/metric-session.model";
import { Preferences } from "@capacitor/preferences";

@Injectable({
  providedIn: "root",
})
export class BluetoothService {
  isScanning: boolean = false;
  devices: ScanResult[] = [];
  connectedDevice: ScanResult | null = null;

  SERVICE_CHARACTERISTICS: {
    [key: string]: { name: string; alert_characteristic: string };
  } = {
    "00001811-0000-1000-8000-00805f9b34fb": {
      name: "haloPlus",
      alert_characteristic: "00002a46-0000-1000-8000-00805f9b34fb",
    },
    "2456e1b9-26e2-8f83-e744-f34f01e9d701": {
      name: "shearwell",
      alert_characteristic: "2456e1b9-26e2-8f83-e744-f34f01e9d703",
    },
  };
  eidTagDataBuffer: string = "";
  eidRegex: RegExp = /^(\d{3})\s?(\d{6})\s?(\d{5,6})$/;
  private _isScanningSubject = new BehaviorSubject<boolean>(this.isScanning);
  private _devicesSubject = new BehaviorSubject<ScanResult[]>([]);
  private _connectedDeviceSubject = new BehaviorSubject<ScanResult | null>(null);
  private _connectedDeviceScanReturnSubject = new BehaviorSubject<EidTagData | null>(null);
  private readonly SCAN_DEBOUNCE_TIME = 500; // 0.5 second debounce
  private _debouncedScanReturn = this._connectedDeviceScanReturnSubject.pipe(debounceTime(this.SCAN_DEBOUNCE_TIME));
  private _notificationsEnabled = true;

  get isScanningSubject() {
    return this._isScanningSubject.asObservable();
  }

  get devicesSubject() {
    // TODO: Look into distinctUntilChanged to see if it can see if there's a change inside of an object inside of an array so we can get rid of the extra devices array
    return this._devicesSubject.asObservable();
  }

  get connectedDeviceScanReturn() {
    return this._debouncedScanReturn;
  }

  get connectedDeviceSubject() {
    return this._connectedDeviceSubject.asObservable();
  }

  clearDevices() {
    this.devices = [];
    this._devicesSubject.next([]);
  }

  constructor(
    private ngZone: NgZone,
    private core: CoreService,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
  ) {}

  async initializeBlePopUp() {
    const alreadyInitialized = await Preferences.get({ key: "bleInitialized" });
    if (!alreadyInitialized.value) {
      const alert = await this.alertCtrl.create({
        header: "FlockFinder Bluetooth",
        subHeader: "if you have an EID reader",
        backdropDismiss: false,
        message:
          "FlockFinder would like to use Bluetooth to connect to EID readers. You will be asked to approve access to Bluetooth after this prompt.",
        buttons: ["Okay"],
      });
      await alert.present();
      await alert.onDidDismiss();
      await Preferences.set({ key: "bleInitialized", value: "true" });
      this._initializeBle();
    }
  }

  private async _initializeBle() {
    try {
      await BleClient.initialize();
    } catch (error) {
      this.core.errorToast("To use EID readers, please enable Bluetooth in your device settings");
    }
  }

  async isEnabled() {
    return await BleClient.isEnabled();
  }

  async requestEnable() {
    await BleClient.requestEnable();
  }

  async scanForDevices() {
    if (this.isScanning) {
      await this.stopScanning();
    }
    this.isScanning = true;
    this._isScanningSubject.next(this.isScanning);
    try {
      await BleClient.requestLEScan(
        {
          allowDuplicates: false,
          services: Object.keys(this.SERVICE_CHARACTERISTICS),
        },
        result => {
          this.ngZone.run(() => {
            this.onDeviceDiscovered(result);
          });
        },
      );
      setTimeout(async () => {
        await this.stopScanning();
      }, 5000);
    } catch (error) {
      await this.stopScanning();
      this.core.errorToast("Error scanning for Bluetooth devices");
      // assume this is because the initializeBle failed
      this._initializeBle();
    }
  }

  async stopScanning() {
    try {
      await BleClient.stopLEScan();
      console.log("STOPPED SCANNING =============================");
      this.isScanning = false;
      this._isScanningSubject.next(this.isScanning);
    } catch (error) {
      this.isScanning = false;
      this._isScanningSubject.next(this.isScanning);
      this.core.errorToast("Error stopping Bluetooth LE scan");
    }
  }

  async connectToDevice(device: ScanResult) {
    const currentDevice = await firstValueFrom(this._connectedDeviceSubject.pipe(take(1)));
    this._devicesSubject.next([]);
    if (currentDevice && currentDevice.device.deviceId === device.device.deviceId) {
      this.core.successToast("Device already connected");
      return;
    } else if (currentDevice) {
      await this.disconnectFromDevice(currentDevice.device.deviceId);
    }

    const loading = await this.loadingCtrl.create({
      message: "Connecting to device...",
      keyboardClose: true,
    });
    await loading.present();

    try {
      await BleClient.connect(device.device.deviceId, async deviceId => {
        this._connectedDeviceSubject.next(null);
        const alert = await this.alertCtrl.create({
          header: "You have disconnected from the device",
          message:
            "Ensure your device is ready to connect if you want to reconnect, or click Ok to continue with no device connected",
          buttons: [
            {
              text: "Ok",
              role: "cancel",
              handler: () => {
                this._connectedDeviceSubject.next(null);
              },
            },
            {
              text: "Reconnect",
              role: "confirm",
              handler: async () => {
                try {
                  await this.connectToDevice(device);
                } catch (error) {
                  console.log("Error reconnecting to device", error);
                  this.core.errorToast("Error reconnecting to device");
                }
              },
            },
          ],
        });
        await alert.present();
      });
      console.log("CONNECTED TO DEVICE =============================");
      this._connectedDeviceSubject.next(device);
      await this.getDataFromDevice();
      await loading.dismiss();
    } catch (error) {
      this.core.errorToast("Error connecting to device");
      await loading.dismiss();
    }
  }

  async getDataFromDevice() {
    console.log("getDataFromDevice");
    const device = await firstValueFrom(this._connectedDeviceSubject.pipe(take(1)), { defaultValue: null });
    console.log("getDataFromDevice -- device", device);

    if (!device) {
      return;
    }
    try {
      const services = await BleClient.getServices(device.device.deviceId);
      if (services.length > 0) {
        for (let i = 0; i < services.length; i++) {
          const uuid = services[i].uuid;
          if (uuid in this.SERVICE_CHARACTERISTICS) {
            console.log(uuid, "uuid");
            const characteristic = this.SERVICE_CHARACTERISTICS[uuid].alert_characteristic;
            const deviceName = this.SERVICE_CHARACTERISTICS[uuid].name;
            // start notifications
            await BleClient.startNotifications(device.device.deviceId, uuid, characteristic, async (data: DataView) => {
              const parsedData = this.parseEid(data);
              if (deviceName === "shearwell") {
                const eidData: EidTagData | null = this.combineDataPacketsShearwell(parsedData);
                if (eidData) {
                  console.log("===eidData====", eidData);
                  this.ngZone.run(() => {
                    if (this._notificationsEnabled) {
                      this._connectedDeviceScanReturnSubject.next(eidData);
                      Haptics.vibrate();
                    }
                  });
                }
              } else if (deviceName === "haloPlus") {
                // parsedData = 900255202371596
                const eidData: EidTagData | null = this.combineDataPacketsHaloPlus(parsedData);
                console.log("===eidData====", eidData);

                if (eidData) {
                  // Check if all fields are zeros
                  const isAllZeros =
                    eidData.animalNumber === "000000" &&
                    eidData.countryCode === "000" &&
                    eidData.flockNumber === "000000";

                  if (isAllZeros) {
                    this.ngZone.run(async () => {
                      const alert = await this.alertCtrl.create({
                        header: "No Tag Found",
                        message: "Please ensure the tag is properly positioned near the reader and try again.",
                        buttons: ["OK"],
                      });
                      await alert.present();
                    });
                  } else {
                    this.ngZone.run(() => {
                      if (this._notificationsEnabled) {
                        this._connectedDeviceScanReturnSubject.next(eidData);
                        Haptics.vibrate();
                      }
                    });
                  }
                }
              } else {
                this.core.errorToast("Unknown device");
                throw new Error("Unknown device");
              }
            });
          }
        }
      }
    } catch (e) {
      this.core.errorToast("Error getting data from device");
    }
  }

  parseEid(value: DataView): string {
    // convert DataView from BLE to string
    const valueArray = new Uint8Array(value.buffer);
    // get the first 8 bytes
    const eid = valueArray;
    // convert to hex
    const hex = Array.from(eid)
      .map(byte => byte.toString(16))
      .join("");
    const hexToAscii = this.hex2a(hex);
    // Remove non-numeric characters from hexToAscii
    const numericHexToAscii = hexToAscii.replace(/[^0-9]/g, "");
    return numericHexToAscii;
  }

  combineDataPacketsShearwell(data: string): EidTagData | null {
    if (this.eidTagDataBuffer === "") {
      // if first packet is 8 bytes and has trailing 0, remove it
      if (data.length === 8 && data.startsWith("0")) {
        data = data.slice(1);
      }
    }
    this.eidTagDataBuffer += data;

    // Check if there's a '0' between country code and flock number
    if (this.eidTagDataBuffer.length >= 4 && this.eidTagDataBuffer.charAt(3) === "0") {
      // Remove the extra '0' between country code and flock number
      this.eidTagDataBuffer = this.eidTagDataBuffer.substring(0, 3) + this.eidTagDataBuffer.substring(4);
    }

    if (this.eidTagDataBuffer.match(this.eidRegex)) {
      const matches = this.eidTagDataBuffer.match(this.eidRegex);
      if (matches) {
        // reset buffer
        this.eidTagDataBuffer = "";
        return { countryCode: matches[1], flockNumber: matches[2], animalNumber: matches[3] };
      }
    }
    return null;
  }

  combineDataPacketsHaloPlus(data: string): EidTagData | null {
    // Check if there's a '0' between country code and flock number
    if (data.length >= 4 && data.charAt(3) === "0") {
      // Remove the extra '0' between country code and flock number
      data = data.substring(0, 3) + data.substring(4);
    }

    const matches = data.match(this.eidRegex);
    if (matches) {
      return { countryCode: matches[1], flockNumber: matches[2], animalNumber: matches[3] };
    }
    return null;
  }

  hex2a(hexx: string): string {
    var hex = hexx.toString(); //force conversion
    var str = "";
    for (var i = 0; i < hex.length; i += 2)
      // cannot use substring here
      str += String.fromCharCode(parseInt(hex.substring(i, i + 2), 16));
    return str;
  }

  async disconnectFromDevice(deviceId: string) {
    const loading = await this.loadingCtrl.create({
      message: "Disconnecting from device...",
      keyboardClose: true,
    });
    await loading.present();
    try {
      const services = await BleClient.getServices(deviceId);
      for (let i = 0; i < services.length; i++) {
        const uuid = services[i].uuid;
        if (uuid in this.SERVICE_CHARACTERISTICS) {
          await BleClient.stopNotifications(deviceId, uuid, this.SERVICE_CHARACTERISTICS[uuid].alert_characteristic);
        }
      }
      await BleClient.disconnect(deviceId);
      console.log("DISCONNECTED FROM DEVICE =============================");
      this._connectedDeviceSubject.next(null);
      await loading.dismiss();
    } catch (error) {
      console.log("ERROR DISCONNECTING FROM DEVICE", error);
      await loading.dismiss();
    }
  }

  onDeviceDiscovered(device: ScanResult) {
    console.log("DEVICE DISCOVERED", device);
    const existingDeviceIndex = this.devices.findIndex(d => d.device.deviceId === device.device.deviceId);

    if (existingDeviceIndex > -1) {
      this.devices[existingDeviceIndex] = device;
    } else {
      this.devices.push(device);
    }

    this.devices.sort((a, b) => (b.rssi ?? -Infinity) - (a.rssi ?? -Infinity)); // Sort devices by RSSI
    this._devicesSubject.next([...this.devices]);
  }

  async pauseNotifications() {
    this._notificationsEnabled = false;
  }

  async resumeNotifications() {
    this._notificationsEnabled = true;
  }
}
