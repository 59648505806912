import { Component, OnInit, Input, OnDestroy, ViewChild } from "@angular/core";
import { UserHolding } from "../../../account/account.model";
import { Router } from "@angular/router";
import { AccountService } from "../../../account/account.service";
import { Subscription } from "rxjs";
import { CoreService } from "../../../shared/core/core.service";
import { IonSelect } from "@ionic/angular";
import { AuthenticationService } from "src/app/shared/auth/authentication-service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() isLoading: boolean;
  @Input() isTransparent: boolean = false;
  @Input() set pageActive(isActive: boolean) {
    if (isActive) {
      this.initializeSubscriptions();
    } else {
      this.cleanup();
    }
  }
  @Input() dataLoaded: boolean = false;
  @Input() filter: () => void = null;
  @Input() refresh: () => void = null;
  @Input() sort: (event: MouseEvent) => void = null;

  selectedHolding: UserHolding;
  userHoldings: UserHolding[] = [];
  isDesktop: boolean;
  userName: string;
  appVersion: string = environment.version;

  showHoldingInfo = false;
  popupTop = 0;
  popupLeft = 0;

  private _holdingsListener: Subscription;
  private _holdingListener: Subscription;

  @ViewChild("holdingSelect") holdingSelect: IonSelect;

  constructor(
    private accountService: AccountService,
    private router: Router,
    private core: CoreService,
    private authService: AuthenticationService,
  ) {
    this.core.isDesktopView().subscribe(isDesktop => {
      this.isDesktop = isDesktop;
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.cleanup();
  }

  public closeHoldingInfo() {
    // Add closing animation
    const holdingInfoPopup = document.querySelector(".holding-info-popup");
    if (holdingInfoPopup) {
      holdingInfoPopup.classList.add("closing");

      // Wait for animation to complete before hiding
      setTimeout(() => {
        this.showHoldingInfo = false;
      }, 200); // Match animation duration
    } else {
      this.showHoldingInfo = false;
    }
  }

  toggleHoldingInfo(event: MouseEvent) {
    event.stopPropagation(); // Prevent event from reaching the overlay
    this.showHoldingInfo = !this.showHoldingInfo;

    if (this.showHoldingInfo) {
      // Remove closing class if it exists when showing popup
      setTimeout(() => {
        const holdingInfoPopup = document.querySelector(".holding-info-popup");
        if (holdingInfoPopup) {
          holdingInfoPopup.classList.remove("closing");
        }
      }, 0);

      const avatarButton = event.currentTarget as HTMLElement;
      const buttonRect = avatarButton.getBoundingClientRect();

      // Account for the sidebar offset in desktop view
      if (this.isDesktop) {
        const sidebarWidth = 240;
        this.popupLeft = buttonRect.left + window.scrollX - sidebarWidth;
      } else {
        this.popupLeft = buttonRect.left + window.scrollX;
      }

      this.popupTop = buttonRect.bottom + window.scrollY + 5;
    }
  }

  storeHoldingSelection(event: CustomEvent) {
    const selectedHoldingId = event.detail.value;
    this.selectedHolding = this.userHoldings.find(holding => holding.id === selectedHoldingId);
    for (let holding of this.userHoldings) {
      if (holding.id === selectedHoldingId) {
        this.accountService.updateSelectedHolding(holding);
      }
    }
  }

  storeHoldingSelectionAndClosePopup(event: CustomEvent) {
    // Use the existing storeHoldingSelection logic
    this.storeHoldingSelection(event);

    // Close the popup
    this.showHoldingInfo = false;
  }

  navigateTo(url: string) {
    this.router.navigateByUrl(url);
  }

  customPopoverOptions: any = {
    header: "Select Holding",
    message: "To edit holdings please go to your account settings",
  };
  get isDemoHoldingGetter() {
    return this.selectedHolding?.holdingName === "FL/OCK/DEMO";
  }

  openHoldingSelect() {
    if (this.holdingSelect) {
      this.holdingSelect.open();
    }
  }

  onLogout() {
    this.authService.SignOut();
  }

  private initializeSubscriptions() {
    console.log("initializeSubscriptions");
    this.cleanup(); // Clean up any existing subscriptions first

    this._holdingsListener = this.accountService.userHoldings.subscribe(_userHoldings => {
      this.userHoldings = [..._userHoldings.filter(holding => holding.permissionGranted)];
    });

    this._holdingListener = this.accountService.selectedUserHoldingGlobal.subscribe(holding => {
      this.selectedHolding = holding;
    });
  }

  goToHelp() {
    this.core.goToHelp();
  }

  private cleanup() {
    if (this._holdingsListener) {
      this._holdingsListener.unsubscribe();
      this._holdingsListener = null;
    }
    if (this._holdingListener) {
      this._holdingListener.unsubscribe();
      this._holdingListener = null;
    }
    // Hide the dropdown when cleaning up
    this.showHoldingInfo = false;
  }
}
