import { HttpInterceptorFn, HttpErrorResponse } from "@angular/common/http";
import { inject } from "@angular/core";
import { ModalController, ToastController } from "@ionic/angular";
import { catchError } from "rxjs/operators";
import { of, throwError } from "rxjs";
import { AccountService } from "src/app/account/account.service";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "./authentication-service";
import { UpgradeSubscriptionComponent } from "src/app/payments/upgrade-subscription/upgrade-subscription.component";

export const ErrorInterceptor: HttpInterceptorFn = (req, next) => {
  const toastController = inject(ToastController);
  const accountService = inject(AccountService);
  const authService = inject(AuthenticationService);
  const modalCtrl = inject(ModalController);

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      // Prevent infinite loops on error reporting failures
      if (req.url.includes("send-error-report")) {
        console.error("Failed to send error report", error);
        // If error reporting fails, log out the user and show message
        authService.SignOut();
        showErrorToast(
          "Something went seriously wrong - please try logging in again",
          toastController,
          "danger",
          10000,
        );
        return throwError(() => error);
      }

      let errorMsg = "";

      // client-side error
      if (error.error instanceof ErrorEvent) {
        errorMsg = `Error: ${error.error.message}`;
        showErrorToast(errorMsg, toastController);
      }
      // server-side error
      else {
        if (error.status === 500 && environment.production) {
          accountService
            .sendErrorReport(error.message)
            .pipe(
              catchError(reportError => {
                console.error("Failed to send error report", reportError);
                // If error reporting fails, log out user
                authService.SignOut();
                showErrorToast(
                  "Something went seriously wrong - please try logging in again",
                  toastController,
                  "danger",
                  10000,
                );
                return of(null);
              }),
            )
            .subscribe();
        } else if (error.status === 402) {
          // Payment required - existing logic
          const errorMessage =
            error.error?.detail || "Your subscription has expired, please upgrade your subscription.";
          showUpgradeSubscription(errorMessage, modalCtrl);
        } else {
          const _error = error.error?.detail || error.message || error;
          errorMsg = `Error: ${_error}`;
          if (_error.toLowerCase().includes("token")) {
            authService.SignOut();
            errorMsg += " - please login again";
          }
          showErrorToast(errorMsg, toastController);
        }
      }
      return throwError(() => error);
    }),
  );
};

// Helper functions
async function showErrorToast(
  errorMessage: string,
  toastController: ToastController,
  color: string = "tertiary",
  duration: number = 4000,
) {
  const toast = await toastController.create({
    message: errorMessage,
    position: "top",
    duration: duration,
    color: color,
  });
  toast.present();
}

async function showUpgradeSubscription(errorMessage: string, modalCtrl: ModalController) {
  const modal = await modalCtrl.create({
    component: UpgradeSubscriptionComponent,
    componentProps: {
      errorMessage: errorMessage,
    },
  });
  await modal.present();
}
