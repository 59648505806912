<ion-app>
  <ion-split-pane [when]="isDesktop" contentId="main-content" class="my-flock-pane">
    <ion-menu
      *ngIf="isDesktop"
      id="flockMenuBar"
      contentId="main-content"
      type="overlay"
      class="my-flock-menu"
      disabled="true"
    >
      <ion-header mode="md" class="ion-no-border">
        <ion-toolbar color="primary">
          <div class="logo-container" (click)="goToHome()">
            <img src="assets/logo/FF_Full_Logo_White_Green_HD.png" alt="FlockFinder Logo" />
          </div>
          <div class="holding-selector-container" *ngIf="userHoldings && userHoldings.length > 0">
            <div class="current-holding" (click)="openHoldingSelect()" *ngIf="selectedHolding">
              <div class="holding-avatar">
                {{ selectedHolding.holdingName.charAt(0).toUpperCase() }}
              </div>
              <div class="holding-name">
                {{
                  selectedHolding.holdingName.length > 17
                    ? (selectedHolding.holdingName | slice: 0 : 17) + "..."
                    : selectedHolding.holdingName
                }}
                <ion-icon name="chevron-down-outline" size="small"></ion-icon>
              </div>
            </div>
            <ion-select
              #holdingSelect
              [interfaceOptions]="customPopoverOptions"
              placeholder="select holding"
              (ionChange)="storeHoldingSelection($event)"
              [value]="selectedHolding?.id"
              style="display: none"
            >
              <ion-select-option *ngFor="let holding of userHoldings" [value]="holding.id">
                {{ holding.holdingName }}
              </ion-select-option>
            </ion-select>
          </div>
        </ion-toolbar>
      </ion-header>
      <ion-content color="primary">
        <!-- PAGES SECTION -->
        <div class="menu-section-header">Pages</div>
        <ion-list scroll="false" class="flock-dark-background">
          <ion-menu-toggle *ngIf="isDesktop" auto-hide="false">
            <ion-item
              color="primary"
              lines="none"
              detail="false"
              button
              routerLink="/manage/home"
              class="menu-item"
              [ngClass]="{ 'active-item': isRouteActive('home') }"
            >
              <ion-icon
                [color]="isRouteActive('home') ? 'success' : 'light'"
                size="small"
                name="home"
                slot="start"
              ></ion-icon>
              <ion-label [color]="isRouteActive('home') ? 'success' : 'light'">Dashboard</ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-menu-toggle *ngIf="isDesktop" auto-hide="false">
            <ion-item
              color="primary"
              lines="none"
              detail="false"
              button
              routerLink="/manage/fields"
              class="menu-item"
              [ngClass]="{ 'active-item': isRouteActive('fields') }"
            >
              <ion-icon
                [color]="isRouteActive('fields') ? 'success' : 'light'"
                size="small"
                name="location"
                slot="start"
              ></ion-icon>
              <ion-label [color]="isRouteActive('fields') ? 'success' : 'light'">Fields</ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-menu-toggle *ngIf="isDesktop" auto-hide="false">
            <ion-item
              color="primary"
              lines="none"
              detail="false"
              button
              routerLink="/manage/flock"
              class="menu-item"
              [ngClass]="{ 'active-item': isRouteActive('flock') }"
            >
              <ion-icon
                [color]="isRouteActive('flock') ? 'success' : 'light'"
                size="small"
                [src]="isRouteActive('flock') ? flockImgActive : flockImg"
                slot="start"
              ></ion-icon>
              <ion-label [color]="isRouteActive('flock') ? 'success' : 'light'">Livestock</ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-menu-toggle *ngIf="isDesktop" auto-hide="false">
            <ion-item
              color="primary"
              lines="none"
              detail="false"
              button
              routerLink="/manage/medical"
              class="menu-item"
              [ngClass]="{ 'active-item': isRouteActive('medical') }"
            >
              <ion-icon
                [color]="isRouteActive('medical') ? 'success' : 'light'"
                size="small"
                name="bandage"
                slot="start"
              ></ion-icon>
              <ion-label [color]="isRouteActive('medical') ? 'success' : 'light'">Treatments</ion-label>
            </ion-item>
          </ion-menu-toggle>

          <!-- More Section (Collapsible) -->
          <div
            class="section-item"
            (click)="toggleMoreSection()"
            [ngClass]="{
              'section-active':
                isRouteActive('medical-cabinet') ||
                isRouteActive('suppliers') ||
                isRouteActive('movement-history') ||
                isRouteActive('labels') ||
                isRouteActive('data'),
            }"
          >
            <div class="section-item-left">
              <ion-icon
                [color]="
                  isRouteActive('medical-cabinet') ||
                  isRouteActive('suppliers') ||
                  isRouteActive('movement-history') ||
                  isRouteActive('labels') ||
                  isRouteActive('data')
                    ? 'success'
                    : 'light'
                "
                size="small"
                name="ellipsis-horizontal"
              ></ion-icon>
              <span
                [ngClass]="{
                  'success-text':
                    isRouteActive('medical-cabinet') ||
                    isRouteActive('suppliers') ||
                    isRouteActive('movement-history') ||
                    isRouteActive('labels') ||
                    isRouteActive('data'),
                }"
                >More</span
              >
            </div>
            <ion-icon
              [color]="
                isRouteActive('medical-cabinet') ||
                isRouteActive('suppliers') ||
                isRouteActive('movement-history') ||
                isRouteActive('labels') ||
                isRouteActive('data')
                  ? 'success'
                  : 'light'
              "
              size="small"
              [name]="isMoreExpanded ? 'chevron-up' : 'chevron-down'"
            ></ion-icon>
          </div>

          <div class="section-children" [ngClass]="{ expanded: isMoreExpanded }">
            <ion-menu-toggle auto-hide="false">
              <ion-item
                color="primary"
                lines="none"
                detail="false"
                button
                (click)="navManage('/manage/medical/medical-cabinet')"
                class="menu-item"
                [ngClass]="{ 'active-item': isRouteActive('medical-cabinet') }"
              >
                <ion-icon
                  [color]="isRouteActive('medical-cabinet') ? 'success' : 'light'"
                  size="small"
                  name="medical-outline"
                  slot="start"
                ></ion-icon>
                <ion-label [color]="isRouteActive('medical-cabinet') ? 'success' : 'light'">Medical Cabinet</ion-label>
              </ion-item>
            </ion-menu-toggle>

            <ion-menu-toggle auto-hide="false">
              <ion-item
                color="primary"
                lines="none"
                detail="false"
                button
                (click)="navManage('/manage/medical/suppliers')"
                class="menu-item"
                [ngClass]="{ 'active-item': isRouteActive('suppliers') }"
              >
                <ion-icon
                  [color]="isRouteActive('suppliers') ? 'success' : 'light'"
                  size="small"
                  name="location-outline"
                  slot="start"
                ></ion-icon>
                <ion-label [color]="isRouteActive('suppliers') ? 'success' : 'light'">Suppliers</ion-label>
              </ion-item>
            </ion-menu-toggle>

            <ion-menu-toggle auto-hide="false">
              <ion-item
                color="primary"
                lines="none"
                detail="false"
                button
                (click)="navManage('/manage/movement-history')"
                class="menu-item"
                [ngClass]="{ 'active-item': isRouteActive('movement-history') }"
              >
                <ion-icon
                  [color]="isRouteActive('movement-history') ? 'success' : 'light'"
                  size="small"
                  name="swap-horizontal-outline"
                  slot="start"
                ></ion-icon>
                <ion-label [color]="isRouteActive('movement-history') ? 'success' : 'light'">Movements</ion-label>
              </ion-item>
            </ion-menu-toggle>

            <ion-menu-toggle auto-hide="false">
              <ion-item
                color="primary"
                lines="none"
                detail="false"
                button
                (click)="navManage('/manage/labels')"
                class="menu-item"
                [ngClass]="{ 'active-item': isRouteActive('labels') }"
              >
                <ion-icon
                  [color]="isRouteActive('labels') ? 'success' : 'light'"
                  size="small"
                  name="pricetag-outline"
                  slot="start"
                ></ion-icon>
                <ion-label [color]="isRouteActive('labels') ? 'success' : 'light'">Labels</ion-label>
              </ion-item>
            </ion-menu-toggle>

            <ion-menu-toggle auto-hide="false">
              <ion-item
                color="primary"
                lines="none"
                detail="false"
                button
                (click)="navManage('/manage/data')"
                class="menu-item"
                [ngClass]="{ 'active-item': isRouteActive('data') }"
              >
                <ion-icon
                  [color]="isRouteActive('data') ? 'success' : 'light'"
                  size="small"
                  name="cloud-upload-outline"
                  slot="start"
                ></ion-icon>
                <ion-label [color]="isRouteActive('data') ? 'success' : 'light'">Data</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </div>

          <!-- Tools Section -->
          <div class="menu-section-divider"></div>
          <div class="menu-section-header">Tools</div>

          <ion-menu-toggle auto-hide="false">
            <ion-item color="primary" lines="none" detail="false" button (click)="goToAsk()" class="menu-item">
              <ion-icon color="light" size="small" name="chatbubbles" slot="start"></ion-icon>
              <ion-label>Ask FlockFinder</ion-label>
            </ion-item>
          </ion-menu-toggle>

          <!-- Account Section -->
          <div class="menu-section-divider"></div>
          <div class="menu-section-header">Account</div>

          <div
            class="section-item"
            (click)="toggleAccountSection()"
            [ngClass]="{ 'section-active': isRouteActive('holdings') || isRouteActive('account') }"
          >
            <div class="section-item-left">
              <ion-icon
                [color]="isRouteActive('holdings') || isRouteActive('account') ? 'success' : 'light'"
                size="small"
                name="person"
              ></ion-icon>
              <span [ngClass]="{ 'success-text': isRouteActive('holdings') || isRouteActive('account') }">Account</span>
            </div>
            <ion-icon
              [color]="isRouteActive('holdings') || isRouteActive('account') ? 'success' : 'light'"
              size="small"
              [name]="isAccountExpanded ? 'chevron-up' : 'chevron-down'"
            ></ion-icon>
          </div>

          <div class="section-children" [ngClass]="{ expanded: isAccountExpanded }">
            <!-- Add Holding Selector -->

            <ion-menu-toggle auto-hide="false">
              <ion-item
                color="primary"
                lines="none"
                detail="false"
                button
                (click)="navigateTo('/manage/holdings')"
                class="menu-item"
                [ngClass]="{ 'active-item': isRouteActive('holdings') }"
              >
                <ion-icon
                  [color]="isRouteActive('holdings') ? 'success' : 'light'"
                  size="small"
                  name="home-outline"
                  slot="start"
                ></ion-icon>
                <ion-label [color]="isRouteActive('holdings') ? 'success' : 'light'">My Farms</ion-label>
              </ion-item>
            </ion-menu-toggle>

            <ion-menu-toggle auto-hide="false">
              <ion-item
                color="primary"
                lines="none"
                detail="false"
                button
                routerLink="/account"
                class="menu-item"
                [ngClass]="{ 'active-item': isRouteActive('account') }"
              >
                <ion-icon
                  [color]="isRouteActive('account') ? 'success' : 'light'"
                  size="small"
                  name="person-outline"
                  slot="start"
                ></ion-icon>
                <ion-label [color]="isRouteActive('account') ? 'success' : 'light'">Account Settings</ion-label>
              </ion-item>
            </ion-menu-toggle>

            <ion-menu-toggle auto-hide="false">
              <ion-item color="primary" lines="none" detail="false" button (click)="goToHelp()" class="menu-item">
                <ion-icon color="light" size="small" name="help-circle-outline" slot="start"></ion-icon>
                <ion-label>Help</ion-label>
              </ion-item>
            </ion-menu-toggle>

            <ion-menu-toggle auto-hide="false">
              <ion-item color="primary" lines="none" detail="false" button (click)="onLogout()" class="menu-item">
                <ion-icon color="light" size="small" name="log-out-outline" slot="start"></ion-icon>
                <ion-label>Log out</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </div>

          <!-- Additional options that aren't in a section -->
          <div *ngIf="isMobile && !isIOS">
            <div class="menu-section-divider"></div>
            <div class="menu-section-header">External</div>
            <ion-menu-toggle auto-hide="false">
              <ion-item
                color="primary"
                lines="none"
                detail="false"
                button
                (click)="goToFlockFinderWeb()"
                class="menu-item"
              >
                <ion-icon color="light" size="small" name="cloud" slot="start"></ion-icon>
                <ion-label>FlockFinder on the web</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </div>
        </ion-list>
      </ion-content>
      <ion-footer class="ion-no-border ion-text-center">
        <ion-toolbar color="primary">
          <ion-text class="contact-us-text">
            Got a suggestion? let us know at:
            <a href="mailto:hello@flockfinder.co.uk" class="flock-light-text"> hello&#64;flockfinder.co.uk </a>
          </ion-text>
        </ion-toolbar>
        <ion-toolbar color="primary" class="flock-small-font ion-no-margin"> v{{ appVersion }} </ion-toolbar>
      </ion-footer>
    </ion-menu>
    <ion-router-outlet id="main-content"> </ion-router-outlet>
  </ion-split-pane>
</ion-app>
